.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}

.border
{
  border-radius: 5px;
}